<template>
  <div class="all">
    <div class="tab">
      <span
        v-for="(item, i) in pTab"
        @click="tabClk(i)"
        :class="{ check: i == productType }"
        >{{ item }}</span
      >
      <button @click="followClk" class="fistBtn">关注类目</button>
      <button @click="secondBtnClk" class="secondBtn">发布供求</button>
    </div>
    <div class="plist">
      <product-list-item v-for="item in productList" :data="item" />
      <Spin v-show="loading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div>
    <div class="footer">
      <span
        >为你找到<i>{{ productListTotal }}</i
        >条货物信息</span
      >
      <Page
        @on-change="pageChange"
        :total="productListTotal"
        :page-size="6"
        show-elevator
      />
    </div>

    <follow-cate :vmodel="vmodel" @visChange="visChange" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import ProductListItem from './ProductListItem'
import FollowCate from '../form/FollowCate'

export default {
  name: 'ProductList',
  props: {
    loading: Boolean,
  },
  data() {
    return {
      pTab: ['货物供应', '货物采购'],
      vmodel: false,
    }
  },
  methods: {
    ...mapMutations('supplyMarket', ['setProductType']),
    tabClk(i) {
      this.setProductType(i)
      this.$emit('tabClick')
    },
    pageChange(e) {
      this.$emit('pageChange', e)
    },
    secondBtnClk() {
      this.$Modal.warning({
        title: '提示',
        content:
          '<p>如需发布供求信息，请联系客服</p><p>客服热线：13329574939</p>',
      })
    },
    followClk() {
      this.vmodel = true
    },
    visChange(e) {
      this.vmodel = e
    },
  },
  computed: {
    ...mapState('supplyMarket', [
      'productType',
      'productList',
      'productListTotal',
    ]),
  },
  components: {
    ProductListItem,
    FollowCate,
  },
}
</script>

<style scoped>
.all {
  width: 800px;
  min-height: 200px;
}

.tab {
  position: relative;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #e6ebf5;
}

.tab span {
  display: inline-block;
  width: 70px;
  height: 35px;
  text-align: center;
  margin: 20px 50px 0 0;
  font-size: 16px;
  color: #4c4c4c;
  cursor: pointer;
}

.tab span:hover {
  position: relative;
  top: 1px;
  color: #25449a;
  border-bottom: 2px solid #25449a;
}

.tab .check {
  position: relative;
  top: 1px;
  color: #25449a;
  border-bottom: 2px solid #25449a;
}

.tab button {
  position: absolute;
  bottom: 20px;
  width: 80px;
  height: 32px;
  font-size: 14px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.tab .fistBtn {
  right: 90px;
  color: #25449a;
  border: 1px solid #25449a;
  background-color: #fff;
}
.tab .secondBtn {
  right: 0;
  color: #ffffff;
  background-color: #25449a;
}

.plist {
  position: relative;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  height: 60px;
  font-size: 14px;
}
.footer span i {
  margin: 0 10px;
  color: #25449a;
  font-style: normal;
}
</style>
