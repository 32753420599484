<template>
  <div class="all">
    <div class="content w1400">
      <h1>在线交易实时数据</h1>
      <div class="realDataPart">
        <div v-for="item in realTimeData" class="realDataBox">
          <div class="imgBox">
            <img :src="item.img" />
          </div>
          <div class="realData">
            <span>{{ item.mark }}（{{ item.unit }}）</span>
            <span
              >{{ item.allData }}<i>（今日 {{ item.today }}{{ item.unit }}）</i></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RealTimeData',
  data() {
    return {
      realTimeData: [
        {
          img: require('assets/img/supplyMarket/tab1.png'),
          mark: '累计成交量',
          unit: '吨',
          allData: '3216541.30',
          today: '980',
        },
        {
          img: require('assets/img/supplyMarket/tab2.png'),
          mark: '累计成交额',
          unit: '元',
          allData: '3216541.30',
          today: '9,652.20',
        },
        {
          img: require('assets/img/supplyMarket/tab3.png'),
          mark: '累计订单量',
          unit: '笔',
          allData: '43405',
          today: '8',
        },
      ],
    }
  },
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 200px;
  background-color: #25449a;
}

.content {
  height: 100%;
  padding: 20px 0 40px;
  text-align: center;
}

.content h1 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  font-family: Microsoft YaHei;
}

.realDataPart {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  width: 100%;
  height: 80px;
}

.realDataBox {
  display: flex;
  width: 400px;
  height: 100%;
}

.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
}
.imgBox img {
  width: 70px;
  height: 70px;
}

.realData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-left: 20px;
  width: 350px;
  height: 100%;
}

.realData span:nth-child(1) {
  font-size: 16px;
  color: #CCDAFF;
}

.realData span:nth-child(2) {
  font-size: 30px;
  color: #FFFFFF;
}

.realData span:nth-child(2) i {
  font-size: 14px;
  font-style: normal;
  color: #CCDAFF;
}
</style>
