<template>
  <div>
    <real-time-data v-if="isReady" />
    <filter-screen v-if="isReady" @onClick="filterClk" class="mrTop30" />
    <div class="content w1400">
      <product-list
        v-if="isReady"
        :loading="loading"
        @tabClick="getItems"
        @pageChange="pageChange"
      />
      <div>
        <q-rcode />
        <scroll-box v-if="isReady" :option="transOpt" class="mrTop30" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import RealTimeData from './components/layout/RealTimeData'
import FilterScreen from './components/layout/FilterScreen'
import ProductList from './components/layout/ProductList'

import {
  getMenu,
  getItems,
  getUserOrders,
} from 'network/supplyMarket'

export default {
  name: 'SupplyMarket',
  data() {
    return {
      isReady: false,
      pageNum: 1,
      loading: false,
      transOpt: {
        title: '成交动态',
        list: [],
        isHover: false,
        context: (str) =>
          `${str.contactName} 成功${str.tradeType}了${str.cateName}`,
      },
    }
  },
  created() {
    let apis = [this.getMenu(), this.getItems(), this.getUserOrders()]
    Promise.all(apis).then((res) => {
      this.isReady = true
    })
  },
  methods: {
    ...mapMutations('supplyMarket', [
      'setCateList',
      'setAreaList',
      'setTypeList',
      'setProductList',
      'setProductListTotal',
    ]),
    filterClk(e) {
      if (e) {
        this.getMenu()
      }
      this.getItems()
    },
    pageChange(e) {
      this.pageNum = e
      this.getItems()
    },
    /* api 相关 */
    getMenu() {
      return new Promise((resolve) => {
        let delivery = this.filterCondition.delivery
        let authType = this.filterCondition.source
        let cate1 = this.filterCondition.cate
        let provinceId = this.filterCondition.area
        let data = {
          delivery: delivery == 0 ? null : delivery,
          authType: authType == 0 ? null : authType,
          cate1: cate1 == 0 ? null : cate1,
          provinceId: provinceId == 0 ? null : provinceId,
        }
        getMenu(data).then((res) => {
          if (res.code == 0) {
            this.setCateList(res.data1)
            this.setAreaList(res.data2)
            this.setTypeList(res.data3)
            resolve()
          }
        })
      })
    },
    getItems() {
      let delivery = this.filterCondition.delivery
      let authType = this.filterCondition.source
      let type = this.filterCondition.type
      let provinceId = this.filterCondition.area
      let data = {
        delivery: delivery == 0 ? null : delivery,
        authType: authType == 0 ? null : authType,
        cate3: type == 0 ? null : type,
        provinceId: provinceId == 0 ? null : provinceId,
        tradeType: this.productType + 1,
        pageSize: 6,
        pageNum: this.pageNum,
      }
      this.loading = true
      getItems(data).then((res) => {
        this.loading = false
        if (res.code == 0) {
          this.setProductList(res.data.list)
          this.setProductListTotal(res.data.total)
        }
      })
    },
    getUserOrders() {
      getUserOrders().then((res) => {
        if (res.code == 0) {
          this.transOpt.list = res.data.list
        }
      })
    },
  },
  components: {
    RealTimeData,
    FilterScreen,
    ProductList,
  },
  computed: {
    ...mapState('supplyMarket', ['filterCondition', 'productType']),
  },
}
</script>

<style scoped>
.mrTop30 {
  margin-top: 30px;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
