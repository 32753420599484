<template>
  <div class="all w1400">
    <filter-item
      title="类目"
      @onClick="cateClk"
      :id="filterCondition.cate"
      :list="cateList"
      :hasAll="false"
      class="mar"
    />
    <filter-item
      title="地区"
      @onClick="areaClk"
      :id="filterCondition.area"
      :list="areaList"
      class="mar"
    />
    <filter-item
      title="来源"
      @onClick="sourceClk"
      :id="filterCondition.source"
      :list="sourceList"
      class="mar"
    />
    <filter-item
      title="提货"
      @onClick="deliveryClk"
      :id="filterCondition.delivery"
      :list="deliveryList"
      class="mar"
    />
    <filter-item
      title="类别"
      @onClick="typeClk"
      :id="filterCondition.type"
      :list="typeList"
      class="mar"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'FilterScreen',
  data() {
    return {
      sourceList: [
        {
          id: 1,
          name: '散户',
        },
        {
          id: 2,
          name: '基地',
        },
        {
          id: 3,
          name: '钢厂',
        },
      ],
      deliveryList: [
        {
          id: 1,
          name: '送货上门',
        },
        {
          id: 2,
          name: '上门自提',
        },
      ],
    }
  },
  methods: {
    ...mapMutations('supplyMarket', [
      'setCate',
      'setArea',
      'setSource',
      'setDelivery',
      'setType',
    ]),
    cateClk(e) {
      if (this.filterCondition.cate != e.id) {
        this.setCate(e.id)
        // 切换了类目，让其他条件变为全部
        this.setArea(0)
        this.setType(0)
        this.$emit('onClick', true)
      }
    },
    areaClk(e) {
      if (this.filterCondition.area != e.id) {
        this.setArea(e.id)
        // 切换了地区
        this.setType(0)
        this.$emit('onClick', true)
      }
    },
    sourceClk(e) {
      if (this.filterCondition.source != e.id) {
        this.setSource(e.id)
        this.$emit('onClick')
      }
    },
    deliveryClk(e) {
      if (this.filterCondition.delivery != e.id) {
        this.setDelivery(e.id)
        this.$emit('onClick')
      }
    },
    typeClk(e) {
      if (this.filterCondition.type != e.id) {
        this.setType(e.id)
        this.$emit('onClick')
      }
    },
  },
  computed: {
    ...mapState('supplyMarket', [
      'filterCondition',
      'cateList',
      'areaList',
      'typeList',
    ]),
  },
}
</script>

<style scoped>
.all {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e6ebf5;
}
.mar {
  margin-bottom: 10px;
}
</style>
