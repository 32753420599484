<template>
  <div class="itemBox">
    <img :src="fimg" class="pImg" />
    <div class="desc">
      <h1>{{ ftext(data.title, 20) }}</h1>
      <span>{{ ftext(data.description, 90) }}</span>
      <div class="infoRow">
        <Row>
          <Col span="7">
            <span
              >地点：<i>{{ data.location }}</i></span
            >
          </Col>
          <Col span="6">
            <span
              >数量：<i>{{ data.quantity }}</i></span
            >
          </Col>
          <Col span="11">
            <img src="~assets/img/supplyMarket/user.png" />
            <span
              ><i>{{ data.pabCustName }}</i></span
            >
          </Col>
        </Row>
        <Row class="mrTop10">
          <Col span="7">
            <span
              >时间：<i>{{ fdate }}</i></span
            >
          </Col>
          <Col span="6">
            <span
              >提货：<i>{{ data.delivery }}</i></span
            >
          </Col>
          <Col span="11">
            <img src="~assets/img/supplyMarket/anth.png" />
            <span
              ><i>认证{{ data.userType }}</i></span
            >
          </Col>
        </Row>
        <div class="price">
          <div>
            ¥<span>{{ data.price }}</span
            ><b>/吨</b>
          </div>
          <button @click="delClick">查看详情</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductListItem',
  props: {
    data: Object,
  },
  methods: {
    delClick() {
      let routeData = this.$router.resolve({
        path: '/supplyMarket/detail',
        name: 'SupplyMarketDetail',
        query: {
          quota: this.data.quota,
          account: this.data.account,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  computed: {
    ftext() {
      return function (str, len) {
        if (str.length >= len) {
          return str.substr(0, len) + '...'
        } else {
          return str
        }
      }
    },
    fdate() {
      return this.$handle.dateFormat(this.data.created, 'm.d h:f')
    },
    fimg() {
      if (this.data.images && this.data.images.length > 0) {
        return this.$config.supplyMarket.productImg + this.data.images
      } else {
        return require('assets/img/public/noImg.png')
      }
    },
  },
}
</script>

<style scoped>
.itemBox {
  display: flex;
  width: 800px;
  height: 200px;
  padding: 20px 0;
  border-bottom: 1px dashed #e6ebf5;
}

.pImg {
  width: 200px;
}

.desc {
  position: relative;
  padding-left: 25px;
  width: 700px;
  height: 160px;
}

.desc h1 {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.desc > span {
  display: block;
  width: 480px;
  height: 60px;
  margin-top: 10px;
  line-height: 20px;
  color: #000;
  font-size: 14px;
}

.desc i {
  font-style: normal;
  color: #000;
}

.infoRow {
  margin-top: 10px;
  font-size: 14px;
  color: #808080;
}
.infoRow img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.mrTop10 {
  margin-top: 10px;
}

.price {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 110px;
  text-align: right;
  font-family: Microsoft YaHei;
}
.price div {
  font-size: 18px;
  color: #4c4c4c;
}
.price div span {
  margin: 0 5px;
  font-size: 24px;
  color: #fa4242;
  font-weight: 700;
}

.price button {
  margin-top: 25px;
  width: 80px;
  height: 32px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #25449a;
  border: 1px solid #25449a;
  background-color: #fff;
  transition: all 0.2s;
}

.price button:hover {
  color: #ffffff;
  background-color: #25449a;
}
</style>
