<template>
  <Modal
    :value="vmodel"
    @on-visible-change="visChange"
    :mask-closable="false"
  >
    <p slot="header" class="title">
      <span>关注类目</span>
    </p>
    <p>关注类目后，相关最新的供求信息将推送至你的信箱</p>
    <div class="follows">
      <follow-cate-item
        v-for="item in followList"
        :data="item"
        @onClick="onClick"
        class="item"
      />
    </div>
    <div slot="footer">
      <span @click="visChange(false)" class="cancel">取消</span>
      <Button @click="addFollow" class="sureBtn">确认提交</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'

import FollowCateItem from './FollowCateItem'

import { selectFollowAuthUser, addFollowAuthUser } from 'network/supplyMarket'

export default {
  name: 'FollowCate',
  props: {
    vmodel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      followed: [],
    }
  },
  methods: {
    visChange(e) {
      if (e) {
        this.selectFollowAuthUser()
      } else {
        this.followed = []
      }
      this.$emit('visChange', e)
    },
    onClick(id) {
      let index = this.followed.indexOf(id)
      if (index < 0) {
        this.followed.push(id)
      } else {
        this.followed.splice(index, 1)
      }
    },

    /* api相关 */
    selectFollowAuthUser() {
      selectFollowAuthUser().then((res) => {
        if (res.code == 0) {
          this.followed = res.data
            .filter((e) => parseInt(e.tradeType) - 1 == this.productType)
            .map((e) => e.cateId)
        }
      })
    },
    addFollow() {
      let data = {
        mobile: localStorage.getItem('userName'),
        tradeType: this.productType == 0 ? 1 : 2,
        pageTranactionList: this.followed.map((e) => {
          return {
            cateId: e,
          }
        }),
      }
      addFollowAuthUser(data).then((res) => {
        if (res.code == 0) {
          this.$Message.success('关注成功！')
          this.$emit('visChange', false)
        }
      })
    },
  },
  components: {
    FollowCateItem,
  },
  computed: {
    ...mapState('supplyMarket', ['cateList', 'productType']),
    followList() {
      const { cateImg } = this.$config
      return this.cateList.map((e) => {
        const img = cateImg.find((ie) => ie.name == e.name).img || ''
        return {
          id: e.id,
          name: e.name,
          isf: this.followed.includes(e.id),
          img,
        }
      })
    },
  },
}
</script>

<style scoped>
.title {
  text-align: center;
}
.cancel {
  margin-right: 20px;
  cursor: pointer;
  color: #25449A;
}
p {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.follows {
  display: flex;
  flex-wrap: wrap;
  width: 480px;
  margin: 20px auto 0;
}
.item {
  margin: 10px;
}
.sureBtn {
  color: #fff;
  background-color: #25449a;
}
</style>
