import { request } from './request'

// 获取筛选菜单
export function getMenu(data) {
  return request({
    url: '/txapi/tranaction/getMenu',
    method: 'get',
    params: data
  })
}

// 获取产品列表
export function getItems(data) {
  return request({
    url: '/txapi/tranaction/getItems',
    method: 'get',
    params: data
  })
}

// 获取成交动态列表
export function getUserOrders(data) {
  return request({
    url: '/txapi/tranaction/getUserOrders',
    method: 'get',
    params: data
  })
}

// 获取成交动态列表
export function getItemsInfo(data) {
  return request({
    url: '/txapi/tranaction/getItemsInfo',
    method: 'get',
    params: data
  })
}

// 获取关注
export function selectFollowAuthUser() {
  return request({
    url: '/txapi/tranaction/selectFollowAuthUser',
    method: 'get',
    params: { mobile: localStorage.getItem('userName') }
  })
}

// 关注类目
export function addFollowAuthUser(data) {
  return request({
    url: '/txapi/tranaction/addFollowAuthUser',
    method: 'post',
    data: data
  })
}

// // 添加关注
// export function addFollowAuthUser(data) {
//   return request({
//     url: '/txapi/tranaction/addFollowAuthUser',
//     method: 'post',
//     data: data
//   })
// }

// // 取消关注
// export function delFollowAuthUser(data) {
//   return request({
//     url: '/txapi/tranaction/delFollowAuthUser',
//     method: 'post',
//     data: data
//   })
// }
