<template>
  <div @click="onClick" class="item">
    <img :src="data.img" />
    <span>{{ data.name }}</span>
    <span :class="followTextClass">{{ followText }}</span>
  </div>
</template>

<script>
export default {
  name: 'FollowCateItem',
  props: {
    data: Object,
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.data.id)
    },
  },
  computed: {
    followText() {
      return this.data.isf ? '取消关注' : '关注'
    },
    followTextClass() {
      return this.data.isf ? 'followed' : 'noFollow'
    },
  },
}
</script>

<style scoped>
.item {
  width: 75px;
  padding: 5px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #000;
}
.item img {
  width: 100%;
}
.item span:nth-of-type(2) {
  display: inline-block;
  width: 65px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;

}
.followed {
  color: #fff;
  background-color: #25449a;
}
.noFollow {
  color: #fff;
  background-color: #B2B2B2;
}
</style>
